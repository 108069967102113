<template>
  <td v-tooltip="lang.attentionRequests.navbar.copyView[languageSelected]">
    <feather-icon
      @click="copyView"
      icon="CopyIcon"
      class="cursor-pointer text-white pt-2"
      style="width:20px !important"
    ></feather-icon>
  </td>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CopyView',
  props: {
    baseUrl: String
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'activeChatUser'])
  },
  methods: {
    copyView() {
      let dummy = document.createElement('input')
      const baseUrl = this.baseUrl || window.location.href
      let text = `${baseUrl}?chat=${this.activeChatUser._id}`

      document.body.appendChild(dummy)
      dummy.value = text
      dummy.select()
      document.execCommand('copy')
      document.body.removeChild(dummy)

      this.$notify.success(
        this.lang.attentionRequests.navbar.copySuccess.title[
          this.languageSelected
        ],
        this.lang.attentionRequests.navbar.copySuccess.text[
          this.languageSelected
        ]
      )
    }
  }
}
</script>
